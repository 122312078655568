import FadeInSection from '../../components/FadeInSection';
import { Grid, GridItem, GridRow } from '../../components/Grid';
import { LiquidButton } from '../../components/LiquidButton';
import { Text as Paragraph } from '../../components/Typography';
import {
  BannerActions,
  BannerAssetsWrapper,
  BannerBackground,
  BannerContent,
  BannerContentWrapper,
  BannerGrid,
  BannerProduct,
  BannerProductImage,
  BannerTool,
  BannerToolImage,
  BannerWrapper,
  ButtonDesktop,
  ButtonMobile,
  Category,
  DesktopSVG,
  MetaballsWrapper,
  MobileSVG,
  ProductTitle,
} from './styles';

import { BlobUpperCorner } from '@icons/Blobs';
import { ImagesService } from 'service/images/imagesService';

import { NextLink } from 'components/NextLink';
import type { ProductBanner as TProductBanner } from 'server/repository/hygraph/generated/graphqlTypes';
import { TrackingService } from 'service/tracking/trackingService';
import { COLORS } from 'styles/constants';
import type { ISharedTemplateProps, UseExtendedImage } from 'templates/TemplateMapper/types';

const LiquidButtonElement = ({
  text = '',
  width,
  height,
  ctaColor = '#EFDBB2',
  textColor = '#000',
}: {
  text: string | undefined;
  width: number;
  height: number;
  ctaColor?: string;
  textColor?: string;
}) => (
  <LiquidButton text={text} height={height} width={width} color={ctaColor} textColor={textColor} />
);

interface IProductBanner extends ISharedTemplateProps {
  templateProps: UseExtendedImage<TProductBanner, 'backgroundImage'>;
}

export const ProductBanner = ({
  templateProps,
  primarySubBrandColor,
  templateIndex = 0,
  components,
}: IProductBanner) => {
  const { backgroundImage, category, product, description, buttonColor, link, toolImage } =
    templateProps;
  const btnHex = primarySubBrandColor
    ? primarySubBrandColor
    : buttonColor === 'Light'
      ? COLORS.secondary
      : COLORS.primary;
  const id = String(Math.floor(Math.random() * (10000 - 0)));
  const componentName = `${id}-product-banner`;
  const productTeaserCardClickContainer = () => {
    let positionNumber = 0;
    for (let i = 0; i <= templateIndex; i++) {
      if (components) {
        const typename = components[i].__typename;
        if (typename !== 'Spacer') {
          positionNumber += 1;
        }
      }
    }

    TrackingService.productTeaserCardClick(
      product?.productTitle || 'na',
      -1,
      product?.productTitle || 'na',
      positionNumber || 0,
      'single product',
    );
  };

  return (
    <BannerWrapper>
      {backgroundImage && (
        <BannerBackground>
          <MobileSVG>
            <svg
              height="100%"
              width="100%"
              viewBox="0 0 320 572"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <clipPath id={`${componentName}-mask-mobile`}>
                  <path
                    d="M0 572V11C0 11 72.2222 0.526245 159.333 0.526245C246.444 0.526245 320 11 320 11V572C320 572 246.444 561.556 159.333 561.556C72.2222 561.556 0 572 0 572Z"
                    fill="#FA85BD"
                  />
                </clipPath>
              </defs>
              <image
                xlinkHref={ImagesService.getImageUrlWithCompressionParams(
                  backgroundImage?.asset?.[0]?.desktop ?? '',
                  70,
                  320,
                  undefined,
                  true,
                )}
                clipPath={`url(#${componentName}-mask-mobile)`}
                preserveAspectRatio="xMidYMid slice"
                height="572"
                width="320"
              />
            </svg>
          </MobileSVG>
          <DesktopSVG data-test={[`${componentName}-desktop-svg`]}>
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 1440 676"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <clipPath id={`${componentName}-mask-desktop`}>
                  <path
                    d="M0 676V51.9274C0 51.9274 325 0.0174264 717 0.0174264C1109 0.0174264 1440 51.9274 1440 51.9274V676C1440 676 1115 628.932 723 628.932C331 628.931 0 676 0 676Z"
                    fill="#FA85BD"
                  />
                </clipPath>
              </defs>
              <image
                height="676"
                width="1440"
                preserveAspectRatio="xMidYMid slice"
                xlinkHref={ImagesService.getImageUrlWithCompressionParams(
                  backgroundImage?.asset?.[0]?.desktop ?? '',
                  70,
                  1440,
                  undefined,
                  false,
                )}
                clipPath={`url(#${componentName}-mask-desktop)`}
              />
            </svg>
          </DesktopSVG>
        </BannerBackground>
      )}
      <BannerGrid data-test={[`${componentName}-grid`]} className="md:pt-10">
        <Grid>
          <GridRow columns={16}>
            <GridItem
              data-test={[`${componentName}-grid-item-1`]}
              colSpan={7}
              colStart={2}
              className="self-center"
            >
              <BannerContentWrapper data-test={[`${componentName}-content-wrapper`]}>
                <FadeInSection data-test={[`${componentName}-fadein-1`]}>
                  <BannerContent data-test={[`${componentName}-content`]}>
                    {category && (
                      <Category data-test={[`${componentName}-category`]} tag="p" type="lead">
                        {category}
                      </Category>
                    )}
                    {product?.productTitle && (
                      <ProductTitle
                        data-test={[`${componentName}-product-title`]}
                        tag="h3"
                        type="lg"
                      >
                        {product?.productTitle}
                      </ProductTitle>
                    )}
                    {description && (
                      <Paragraph tag="p" type="md" data-test={[`${componentName}-text`]}>
                        {description}
                      </Paragraph>
                    )}
                    {link && (
                      <BannerActions
                        data-test={[`${componentName}-actions`]}
                        onClick={productTeaserCardClickContainer}
                      >
                        <NextLink href={link?.url || ''}>
                          <ButtonMobile>
                            <LiquidButtonElement
                              data-test={[`${componentName}-mobile-liquid-button`]}
                              text={link?.displayName}
                              height={60}
                              width={250}
                              ctaColor={btnHex}
                              textColor={buttonColor === 'Light' ? '#000' : '#FFF'}
                            />
                          </ButtonMobile>
                          <ButtonDesktop>
                            <LiquidButtonElement
                              data-test={[`${componentName}-desktop-liquid-button`]}
                              text={link?.displayName}
                              height={50}
                              width={250}
                              ctaColor={btnHex}
                              textColor={buttonColor === 'Light' ? '#000' : '#FFF'}
                            />
                          </ButtonDesktop>
                        </NextLink>
                      </BannerActions>
                    )}
                  </BannerContent>
                </FadeInSection>
              </BannerContentWrapper>
            </GridItem>
            <GridItem data-test={[`${componentName}-grid-item-2`]} colSpan={8}>
              <FadeInSection data-test={[`${componentName}-fadein-2`]}>
                <BannerAssetsWrapper data-test={[`${componentName}-assets-wrapper`]}>
                  {product && (
                    <BannerProduct data-test={[`${componentName}-product-image`]}>
                      <BannerProductImage
                        width={400}
                        height={400}
                        sources={ImagesService.getPictureSources(product?.productImage)}
                      />
                    </BannerProduct>
                  )}
                  {toolImage && (
                    <BannerTool data-test={[`${componentName}-background-tool-image`]}>
                      <BannerToolImage
                        width={400}
                        height={400}
                        sources={ImagesService.getPictureSources(toolImage?.asset?.[0])}
                        alt=""
                      />
                    </BannerTool>
                  )}
                  <MetaballsWrapper>
                    <div className="md:block hidden w-full h-full">
                      <BlobUpperCorner color="#BBC4BA" customSize={true} />
                    </div>

                    <div className="md:hidden w-full h-full">
                      <BlobUpperCorner color="#BBC4BA" customSize={false} />
                    </div>
                  </MetaballsWrapper>
                </BannerAssetsWrapper>
              </FadeInSection>
            </GridItem>
          </GridRow>
        </Grid>
      </BannerGrid>
    </BannerWrapper>
  );
};
