import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { Text as Paragraph } from '../../components/Typography';
import NextImage from '../../components/NextImage';

export const BannerWrapper = tw.div`
  h-fit
  w-full
  py-20
  md:py-36
  llg:pb-20
  bg-top
  bg-cover
  overflow-hidden
  items-center
  grid
  grid-cols-1
 `;
BannerWrapper.displayName = 'BannerWrapper';

export const BannerBackground = tw.div`
  w-full
  h-full
  overflow-hidden
  col-start-1
  row-start-1
`;
BannerBackground.displayName = 'BannerBackground';

export const MobileSVG = tw.div`
  flex
  w-full
  md:hidden
  overflow-hidden
`;
MobileSVG.displayName = 'MobileSVG';

export const DesktopSVG = tw.div`
  hidden
  md:block
  overflow-hidden
`;
DesktopSVG.displayName = 'DesktopSVG';

export const ButtonMobile = tw.div`
  flex
  md:hidden
`;
MobileSVG.displayName = 'MobileSVG';

export const ButtonDesktop = tw.div`
  hidden
  md:block
`;
DesktopSVG.displayName = 'DesktopSVG';

export const BannerGrid = tw.div`
  md:pt-10
  h-full
  col-start-1
  row-start-1
 `;
BannerGrid.displayName = 'BannerGrid';

export const BannerContentWrapper = tw.div`
  pt-5
  llg:pt-20
  md:flex
  items-center
 `;
BannerContentWrapper.displayName = 'BannerContentWrapper';

export const BannerContent = tw.div`
  px-5
  text-center
  h-full
  md:text-left
`;
BannerContent.displayName = 'BannerContent';

export const BannerActions = tw.div`
  flex
  items-center
  justify-center
  md:justify-start
  relative
  -top-5
`;
BannerActions.displayName = 'BannerActions';

export const BannerAssetsWrapper = tw.div`
text-center
  w-full
  md:h-full
  sm:h-56
  relative
  -mt-0
  md:mt-0
`;
BannerAssetsWrapper.displayName = 'BannerAssetsWrapper';

// xl:w-122
// xl:h-122
export const BannerProduct = tw.div`

  -top-10
  md:top-10
  lg:top-5
  relative
  md:absolute
  md:mt-0
  md:left-20
  z-10
  w-full
  h-full
`;
BannerProduct.displayName = 'BannerProduct';

export const BannerProductImage = styled(NextImage as any)`
  -webkit-transform: rotate(-8deg);
  transform: rotate(-8deg);
  width: fit-content;
`;
BannerProductImage.displayName = 'BannerProductImage';

export const BannerTool = tw.div`
  absolute
  -right-10
  -bottom-24
  md:-right-20
  md:-bottom-40
  lg:-right-32
  lg:-bottom-32
  xl:-right-20
  w-3/5
  z-20
  w-full
  h-full
`;
BannerTool.displayName = 'BannerTool';

export const BannerToolImage = tw(NextImage)`
  object-contain
  mt-7.5
  md:mt-64
`;
BannerToolImage.displayName = 'BannerToolImage';

export const MetaballsWrapper = tw.div`
  relative
  w-fit
  -right-20
  bottom-64
  md:-right-40
  md:-bottom-24
  lg:h-120
  lg:-right-36
  lg:-bottom-20
  z-0
`;
MetaballsWrapper.displayName = 'MetaballsWrapper';

export const Category: any = tw(Paragraph as any)`
  mb-2
  mt-8
  text-gray-300
  md:mt-0
`;
Category.displayName = 'Category';

export const ProductTitle: any = tw.h3`
  mb-3
  text-4xl
  md:text-2xl
  lg:text-6xl
  xl:text-7xl
  sm:leading-9.5
  md:leading-11
`;
ProductTitle.displayName = 'ProductTitle';
